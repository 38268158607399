body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.hoverLink:hover {
    text-decoration: underline;
}

.leaflet-container {
    width: 100%;
    height: 68vh;
}

.markercluster-map {
    height: 68vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.computerMenu {
    margin-top: 30px !important;
}
.wrapperGrid {
    margin: 6.5vh 1vw 0vh 1vw !important;
}

@media only screen and (max-width: 768px) {
    .controlColumn {
        max-width: 85vw !important;
        position: sticky !important;
    }
    .contentColumn {
        max-width: 85vw !important;
    }
    .contentColumnScroll {
        max-width: 85vw !important;
    }
}

@media only screen and (min-width: 769px) {
    main {
        overflow: hidden !important;
    }
    .contentColumn {
        max-height: 89vh !important;
        overflow-y: auto !important;
    }
    .contentColumnScroll {
        overflow-y: inherit;
    }
    .controllerHeader {
        margin-bottom: 5vh !important;
    }
}

.Tooltip-module_tooltip__omIxd {
    background-color: rgba(255, 255, 255, 1);
}
